<template>
  <div class="home">
    <div class="content">
      <div class="left">
        <img src="../assets/images/phone.png" alt="" />
      </div>
      <div class="right">
        <div class="container">
          <h1>塔尖APP</h1>
          <img src="../assets/images/logo.png" alt="" />
          <p>
            在塔尖的世界里，你可以通过有趣，无压力的方式认识新朋友。
            全球热门音乐节、酒吧、派对、度假村、Spa馆等； 塔尖带你玩遍全球。
            最优秀的人都知道塔尖，欢迎你的加入。
          </p>
        </div>
        <div class="download">
          <div class="apple common">
            <img src="../assets/images/apple.png" alt="" />
            <span>iphone版下载</span>
          </div>
          <div class="android common">
            <img src="../assets/images/android.png" alt="" />
            <span>Android版下载</span>
          </div>
        </div>
      </div>
      <ul class="list">
        <li>
          <img src="../assets/images/pic1.png" alt="" />
        </li>
        <li>
          <img src="../assets/images/pic2.png" alt="" />
        </li>
        <li>
          <img src="../assets/images/pic3.png" alt="" />
        </li>
        <li>
          <img src="../assets/images/pic4.png" alt="" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.home {
}
.home .content {
  margin: 3px 0 0 250px;
  width: 800px;
  height: 400px;
  box-sizing: border-box;
}
.home .content .left {
  float: left;
  width: 200px;
  height: 100%;
}
.home .content .left img {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.home .content .right {
  float: right;
  width: 600px;
  height: 100%;
}

.home .content .right .container {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  width: 400px;
  padding-top: 50px;
  box-sizing: border-box;
}
.home .content .right .container h1 {
  font-size: 25px;
  color: #fff;
}
.home .content .right .container img {
  padding-top: 10px;
  width: 100px;
  height: 100px;
  background-size: cover;
}
.home .content .right .container p {
  padding-top: 10px;
  font-size: 14px;
  color: #fff;
}
.home .content .right .download {
  width: 400px;
  margin: 0 auto;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: flex-start;
}
.home .content .right .download .common {
  width: 130px;
  height: 34px;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home .content .right .download .common img {
  width: 30px;
  height: 28px;
  background-size: cover;
}
.home .content .right .download .common span {
  font-size: 14px;
  color: #fff;
}
.home .content .right .download .apple {
  background: #e86162;
}
.home .content .right .download .android {
  background: #3db899;
  margin-left: 10px;
}

.home .content .list {
  list-style: none;
  position: absolute;
  bottom: 20px;
}
.home .content .list li {
  float: left;
  width: 180px;
  height: 180px;
  padding-top: 40px;
  margin-left: 50px;
  box-sizing: border-box;
}
.home .content .list li img {
  widows: 100%;
  height: 100%;
  background-size: cover;
}
</style>